<template>
<div class="user">
    <div class="header">
        <van-icon name="bulb-o" />
        <van-icon name="setting-o" @click="$router.push({name:'setting'})" />
    </div>
    <div class="profile">
        <div class="image">
            <img :src="userAvatar" alt="">
        </div>
        <div v-if="userinfo.id>0" class="username">
            <div class="name">{{userinfo.username}}</div>
            <div class="lv">{{userinfo.is_active?'VIP':'免费'}}会员</div>
        </div>
        <div v-else class="username">
            <div @click="login">请登录</div>
        </div>
        <van-icon class="arrow" name="arrow" />
    </div>
    <div class="vip-banner">
        <img src="@/assets/imgs/vip_banner.png" alt="">
    </div>
    <div class="cards">
        <div class="title">我的账户</div>
        <van-grid :border="false">
            <van-grid-item @click="$router.push({name:'orders'})" icon="orders-o" text="我的订单" />
            <van-grid-item @click="$router.push({name:'team'})" icon="friends-o" text="我的推广" />
            <van-grid-item @click="$router.push({name:'share'})" icon="balance-o" text="推广赚钱" />
            <van-grid-item icon="shop-o" text="积分商城" />
            <van-grid-item icon="gift-card-o" text="兑换课程" />
            <van-grid-item icon="coupon-o" text="我的拼团" />
            <van-grid-item icon="clock-o" text="我的秒杀" />
            <van-grid-item icon="medal-o" text="我的证书" />
        </van-grid>
    </div>
    <div class="cards">
        <div class="title">考试练习</div>
        <van-grid :border="false">
            <van-grid-item icon="completed-o" text="我的考试" />
            <van-grid-item icon="todo-list-o" text="模拟考试" />
            <van-grid-item icon="records-o" text="我的练习" />
            <van-grid-item icon="failure" text="错题本" />
            <van-grid-item icon="flower-o" text="收藏试题" />
        </van-grid>
    </div>
    <Tbar />
</div>
  
</template>
<script>
import cfg from "../config.js";
import Tbar from "../components/Tbar.vue";

export default {
  name: "User",
  data() {
    return {
        userinfo: {
            id: 0,
            username: "",
            avatar: ""
        },
        avatar: require("../assets/logo.png")
    };
  },
  computed:{
    userAvatar(){
        if(this.userinfo.avatar){
            return /^http/.test(this.userinfo.avatar) ? this.userinfo.avatar : cfg._ASSETS_HOST + this.userinfo.avatar;
        }else{
            return require("../assets/logo.png");
        }
    }
  },
  components:{
    Tbar
  },
  mounted() {
    const paramsStr = window.location.search;
    const params = new URLSearchParams(paramsStr);
    let _code = params.get('code');
    if(_code){
        let _url = window.location.origin + '/' + window.location.hash;
        window.location.href = _url;
    }
    let _userinfo = this.$store.state.userinfo;
    if(_userinfo.id>0){
        this.userinfo = _userinfo;
    }
    console.log(_userinfo)
  },
  methods: {
    login(){
        console.log(window.navigator.userAgent);
        console.log(window.IS_WECHAT);
        if(window.IS_WECHAT){
            //TODO 微信获取用户资料并登录
            this.$router.push({name: 'weauth'});
        }else{
            this.$router.push({name:'login'})
        }
    }
  },
};
</script>
<style lang="less" scoped>
.user {
    padding-bottom: 50px;
    .header {
        background: #fff;
        display: flex;
        justify-content: space-between;
        padding: 15px;
        font-size: 20px;
    }
    .profile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        background: #fff;
        .image {
            img {
                height: 50px;
            }
        }
        .username {
            flex: 1;
            text-align: left;
            padding-left: 15px;
            .lv {
                font-size: 12px;
                color: #999;
            }
        }
        .arrow {
            color: #999;
        }
    }
    .vip-banner {
        background: #fff;
        height: 66px;
        padding: 15px 0;
        img {
            width: 100%;
        }
    }
    .cards {
        background: #fff;
        margin-bottom: 15px;
        .title{
            padding: 15px;
            text-align: left;
            font-weight: bold;
        }
    }
}
</style>
